import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["uncompleteTableElement"]
  connect() {
    this.uncompleteTableElementTargets.forEach((element) => {
      if (element.classList.contains('uncompleteTableElement')) { this.#incompleteInvoice(element) }
    })
  }

  #incompleteInvoice(element) {
    this.closest = element.closest("tr")
    if (this.closest.nextElementSibling) {
      this.closest.nextElementSibling.querySelectorAll("td").forEach((td) => {
        td.classList.add("border-white")
      })
    }
    this.closest.querySelectorAll("td").forEach((td) => {
      td.classList.add("bg-grey-light", "border-white")
    })
    this.closest.querySelector("td").classList.add("add-info-svg")
  }
}
