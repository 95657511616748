import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["collapseBs", "chevron", "socialNetwork"]
  connect() {
    $(this.collapseBsTarget).on('show.bs.collapse', (event) => {
      this.#showCollapse()
    })
    $(this.collapseBsTarget).on('hide.bs.collapse', (event) => {
      this.#hideCollapse()
    })
  }

  #showCollapse() {
    if (this.hasChevronTarget) { this.chevronTarget.classList.add("rotate-180") }
    if (this.hasSocialNetworkTarget) { this.socialNetworkTarget.classList.remove("d-none") }
  }

  #hideCollapse() {
    if(this.hasChevronTarget) { this.chevronTarget.classList.remove("rotate-180") }
    if(this.hasSocialNetworkTarget) { this.socialNetworkTarget.classList.add("d-none") }
  }
}
