import flatpickr from "flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { French } from "flatpickr/dist/l10n/fr.js"
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["flatpickr"]
  connect() {
    this.flatpickrTargets.forEach((flatpickrInput) => {
      const dataMinDate = flatpickrInput.dataset.minDate
      const dataMaxDate = flatpickrInput.dataset.maxDate
      const maxDate = dataMaxDate ? dataMaxDate : null
      const minDate = dataMinDate ? dataMinDate : null
      const defaultDate = flatpickrInput.value ? new Date(flatpickrInput.value) : null
      flatpickr(flatpickrInput, {
        "dateFormat": "d/m/Y",
        "locale": French,
        "defaultDate": defaultDate,
        "minDate": minDate,
        "maxDate": maxDate
      });
    })
  }
}
