import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["mainElement", "submitLoader", "preventLoader", "loaderDiv", "avatar"]
  connect() {
    this.loader = "<div class='text-center'><div class='spinner-border color-green-dark ml-3' role='status'><span class='sr-only'>Loading...</span></div></div>"
  }

  addClassForTargetElement(event) {
    const classToAdd = event.currentTarget.dataset.css;
    this.mainElementTarget.classList.add(classToAdd);
  }

  removeClassForTargetElement(event) {
    const classToRemove = event.currentTarget.dataset.css;
    this.mainElementTarget.classList.remove(classToRemove);
  }

  preventLoaderSpinner() {
    this.preventLoaderTarget.innerHTML = this.loader
  }

  addLoaderSpinnerForForm() {
    this.loaderDivTarget.innerHTML = this.loader
  }

  addLoaderSpinner() {
    this.submitLoaderTarget.classList.remove("d-none")
  }

  readURL(event) {
    const input = event.currentTarget
    if (input.files && input.files[0]) {
      const target = this.avatarTarget
      const reader = new FileReader();
      const file = reader.readAsDataURL(input.files[0]);
      reader.onload = function (e) {
        if (e.target.result != undefined) {
          target.style.backgroundImage = 'url(' + e.target.result + ')';
        }
      }
    }
  }
}
