import { Controller } from 'stimulus';
import { csrfToken } from "@rails/ujs"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["tableRow", "newQuotation", "tableBody", "removeLine", "customerDisplay"]
  connect() {
    this.tableRowTargets.forEach(this.#updateDisplayingValue)
    this.#countQuotationTotalVats()
    $('.select2#customerSelection').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateServiceRate(e) {
    const element = e.target
    const serviceCategoryId = e.target.value
    const hallId = e.target.dataset.hallid
    if (serviceCategoryId == "") return

    fetch(`/hall/halls/${hallId}/search_service_category`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken() },
      body: JSON.stringify({ "service_category_id": serviceCategoryId })
    })
      .then(response => response.json())
      .then((data) => {
        const tableRow = element.parentElement.parentElement.parentElement
        const serviceVatRate = tableRow.querySelector(".serviceVatRateInput > .serviceVatRate")
        const serviceVatRateSm = tableRow.querySelector(".serviceVatRateSm")
        let quantity = tableRow.querySelector(".serviceQuantityInput").value
        serviceVatRate.innerText = data.vat_rate
        serviceVatRateSm.innerText = data.vat_rate

        if (quantity != "") {
          this.#updateDisplayingValue(tableRow);
          this.#countQuotationTotalVats();
        }
      })
  }



  #updateDisplayingValue(tableRow) {
    const serviceTotalInclVatInput = tableRow.querySelector(".serviceTotalInclVatInput")
    const serviceTotalExclVatInput = tableRow.querySelector(".serviceTotalExclVatInput")
    const serviceUnitPrice = tableRow.querySelector(".serviceUnitPriceInput").value
    const serviceVatRate = tableRow.querySelector(".serviceVatRateInput > .serviceVatRate").textContent
    const quantity = tableRow.querySelector(".serviceQuantityInput").value
    if (serviceUnitPrice != "" && serviceVatRate != "-" && quantity != "") {
      const totalExclVat = Number(Math.round((parseFloat(serviceUnitPrice) * parseInt(quantity)) + "e2") + "e-2")
      const totalInclVat = Number(Math.round((totalExclVat * (1 + parseFloat(serviceVatRate) / 100)) + "e2") + "e-2")

      const serviceTotalExclVatDisplaySpans = tableRow.querySelectorAll(".serviceTotalExclVatDisplaySpan")
      serviceTotalExclVatDisplaySpans.forEach((serviceTotalExclVatDisplaySpan)=>{
        serviceTotalExclVatDisplaySpan.innerText = totalExclVat
      })
      const serviceTotalInclVatDisplaySpans = tableRow.querySelectorAll(".serviceTotalInclVatDisplaySpan")
      serviceTotalInclVatDisplaySpans.forEach((serviceTotalInclVatDisplaySpan)=>{
        serviceTotalInclVatDisplaySpan.innerText = totalInclVat
      })
      serviceTotalInclVatInput.value = totalInclVat * 100
      serviceTotalExclVatInput.value = totalExclVat * 100
    }
  }

  #countQuotationTotalVats() {
    const quotationTotalWithVatSpan = document.getElementById("quotationTotalWithVat")
    const quotationTotalWithoutVatSpan = document.getElementById("quotationTotalWithoutVat")

    let quotationTotalExclVat = 0
    document.querySelectorAll(".serviceTotalExclVatDisplayCell > .serviceTotalExclVatDisplaySpan").forEach((span) => {

      if (parseFloat(span.textContent)) {
        quotationTotalExclVat += parseFloat(span.textContent)
      }
    })

    quotationTotalWithoutVatSpan.innerText = quotationTotalExclVat.toFixed(2)

    let quotationTotalInclVat = 0
    document.querySelectorAll(".serviceTotalInclVatDisplayCell > .position-relative > .serviceTotalInclVatDisplaySpan").forEach((span) => {
      if (parseFloat(span.textContent)) {
        quotationTotalInclVat += parseFloat(span.textContent)
      }
    })

    quotationTotalWithVatSpan.innerText = quotationTotalInclVat.toFixed(2)
  }

  addQuotationLine(e) {
    const lastId = this.tableRowTargets.pop().id
    const newId = parseInt(lastId, 10) + 1;
    let newQuotationLine = document.querySelector('[id="0"]').outerHTML.replace(/0/g, newId);
    this.tableBodyTarget.insertAdjacentHTML(
      "beforeend", newQuotationLine
    );
    newQuotationLine = document.querySelector(`[id='${newId}']`)
    const removeLines = newQuotationLine.querySelectorAll(".removeLine")
    removeLines.forEach((removeLine) => {
      removeLine.classList.remove("d-none")
    })
    newQuotationLine.querySelectorAll(".serviceTotalInclVatDisplaySmSpan, .serviceUnitPrice, .serviceTotalExclVatDisplaySpan, .serviceVatRate, .serviceVatRateSm, .serviceTotalInclVatDisplaySpan").forEach(el => {
      el.innerText = "-"
    })
  }

  removeQuotationLine(e) {
    const quotationLine = e.target.closest("[data-quotation-target='tableRow']")
    quotationLine.remove();
    this.#countQuotationTotalVats();
  }

  findCustomer(e) {
    const customerId = e.target.value
    const hallId = e.target.dataset.hallid

    fetch(`/hall/halls/${hallId}/search_customer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify({ "customer_id": customerId })
    })
      .then(response => response.json())
      .then((data) => {
        const nameLine = data.name ? `<p class="m-0">${data.name.toUpperCase()}</p>` : ""
        const streetLine = data.street ? `<p class="m-0">${data.street}</p>` : ""
        const cityLine = data.city ? `<p class="m-0">${data.zipcode + " "}${data.city.toUpperCase()}</p>` : ""
        const emailLine = data.email ? `<p class="m-0">${data.email}</p>` : ""
        const phoneLine = data.phone ? `<p class="m-0">${data.phone}</p>` : ""
        this.customerDisplayTarget.innerHTML = nameLine + streetLine + cityLine + emailLine + phoneLine
      })
  }

  updateQuotationsValues(e) {
    const tableRow = e.target.parentElement.parentElement.parentElement
    const quantity = e.target.value
    if (quantity != "") {
      this.#updateDisplayingValue(tableRow);
      this.#countQuotationTotalVats();
    }
  }
}
