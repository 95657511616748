require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
window.$ = window.jQuery = require('jquery');
import "bootstrap";
import "../components/collapse";
import "../components/cookie";
import "../components/form";
import "../components/table";
import "../components/utilities";
import "../components/flatpickr";
import "../components/quotation";
import "../components/invoice";
// import { initSelect2 } from '../components/init_select2';
import 'controllers'
// initSelect2();
