import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ["cookieBanner", "cookieConsent", "acceptGoogleAnalytics"]
  connect() {
    this.disableStr = 'ga-disable-' + this.acceptGoogleAnalyticsTarget;
    this.#checkConsent()
    this.#checkGoogleAnalytics()
  }

  #checkConsent() {
    if (!(document.cookie.indexOf('cookie-consent=true') > -1)) {
      this.#showCookieBanner();
    }
  }

  #checkGoogleAnalytics() {
    if (document.cookie.indexOf(this.disableStr + '=true') > -1) {
      window[this.disableStr] = true;
      this.acceptGoogleAnalyticsTarget.checked = false
    }
  }

  configConsent() {
    this.#setCookie(disableStr, `${!this.acceptGoogleAnalyticsTarget.checked}`, 12)
    window[disableStr] = !this.acceptGoogleAnalyticsTarget.checked
  }

  consent() {
    this.#setCookie("cookie-consent", "true", 12);
    this.#hideCookieBanner();
  }

  #hideCookieBanner() {
    this.cookieBannerTarget.classList.add("d-none");
  }

  #showCookieBanner() {
    this.cookieBannerTarget.classList.remove("d-none");
  }

  #setCookie(name, value, months) {
  let expirationDate = new Date;
  expirationDate.setMonth(expirationDate.getMonth() + months);
  document.cookie = name + "=" + value + ";path=/;expires=" + expirationDate.toGMTString();
  }
}
