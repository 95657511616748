import $ from 'jquery';
import 'select2';
import { csrfToken } from "@rails/ujs"
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["rentTypesForm"]
  connect() {
    $('.select2').select2({ width: '100%', dropdownAutoWidth: true });

    $('.ajaxForm').on("select2:select", function (e) {
      e.currentTarget.querySelector(".ajaxSubmit").click();
    });

    $('.rentTypeInput').on("select2:select", function (e) {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    // const loader = "<div class='text-center'><div class='spinner-border color-green-dark ml-3' role='status'><span class='sr-only'>Loading...</span></div></div>"

    // $('.formWithLoader').on("select2:selecting", function (e) {
    //   console.log('hello');
    //   const targetDiv = document.querySelector(e.currentTarget.dataset.target)
    //   targetDiv.innerHTML = loader
    // });
  }

  insertRentTypes(e) {
    const rentType = e.target.value
    const hallId = e.target.dataset.hallId
    const rentalId = e.target.dataset.rentalId
    const url = `/hall/halls/${hallId}/rentals/${rentalId}/financial_conditions/rent_type_form/?rent_type=${rentType}`
    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "text/plain",
        "X-CSRF-Token": csrfToken()
      },
    })
      .then(response => response.text())
      .then((data) => {
        this.rentTypesFormTarget.innerHTML = data
      })
  }
}
