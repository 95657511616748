import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["ajaxSubmit", "standList"]

  connect() {
  }

  submitAjaxForm(e) {
    this.ajaxSubmitTarget.click()
  }

  closeStandList(e) {
    const input = e.currentTarget.querySelector("input[value='specific_repartition']")
    if (!input.checked) {
      const modal = e.currentTarget.closest(".modal")
      modal.querySelector(".standsForm").classList.add("d-none")
      modal.querySelectorAll(".standListInput").forEach((checkBoxInput) => {
        checkBoxInput.checked = false;
      })
    }
  }

  revealModal(e) {
    e.currentTarget.closest(".modal").querySelector(".standsForm").classList.remove("d-none")
  }

  validateInputs(e) {
    const input = e.currentTarget
    const modal = input.closest(".modal")
    const checkedInput = modal.querySelector(".repartitionInputs").querySelector("input[type=radio]:checked")
    const text = checkedInput.dataset.text
    const target = document.querySelector(checkedInput.dataset.target)
    target.textContent = text;
    if (checkedInput.value == "specific_repartition") {
      let stands = []
      modal.querySelectorAll(".standListInput:checked").forEach((checkBoxInput) => {
        stands.push(checkBoxInput.dataset.stand)
      })
      target.textContent = stands.length + " " + text;
    }
  }
}
