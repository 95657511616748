import { Controller } from 'stimulus';
import { csrfToken } from "@rails/ujs"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["companyDisplay", "customerDisplay", "hallDisplay", "hallLogo", "billerName", "tableRow", "tableBody"]

  connect() {
    this.tableRowTargets.forEach(this.#updateInvoiceDisplayingValue)
    this.#countInvoiceTotalVats()
    document.addEventListener("keydown", this.preventEnterKeyEvent.bind(this));
    $('.select2#companySelection').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $('.select2#customerSelection').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $('.select2#hallSelection').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  disconnect() {
    document.removeEventListener("keydown", this.preventEnterKeyEvent.bind(this));
  }

  preventEnterKeyEvent(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.addInvoiceLine()
    }
  }

  searchCompany(e) {
    const companyId = e.target.value
    fetch(`/accountant/search_company`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify({ "company_id": companyId })
    })
      .then(response => response.json())
      .then((data) => {
        const nameLine = data.name ? `<p class="m-0">${data.name.toUpperCase()}</p>` : ""
        const streetLine = data.street ? `<p class="m-0">${data.street}</p>` : ""
        const cityLine = data.city ? `<p class="m-0">${data.zipcode + " "}${data.city.toUpperCase()}</p>` : ""
        const emailLine = data.email ? `<p class="m-0">${data.email}</p>` : ""
        const phoneLine = data.phone ? `<p class="m-0">${data.phone}</p>` : ""
        this.companyDisplayTarget.innerHTML = nameLine + streetLine + cityLine + emailLine + phoneLine
      })
  }

  findCustomer(e) {
    const customerId = e.target.value
    const hallId = e.target.dataset.hallid

    fetch(`/hall/halls/${hallId}/search_customer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify({ "customer_id": customerId })
    })
      .then(response => response.json())
      .then((data) => {
        const nameLine = data.name ? `<p class="m-0">${data.name.toUpperCase()}</p>` : ""
        const streetLine = data.street ? `<p class="m-0">${data.street}</p>` : ""
        const cityLine = data.city ? `<p class="m-0">${data.zipcode + " "}${data.city.toUpperCase()}</p>` : ""
        const emailLine = data.email ? `<p class="m-0">${data.email}</p>` : ""
        const phoneLine = data.phone ? `<p class="m-0">${data.phone}</p>` : ""
        this.customerDisplayTarget.innerHTML = nameLine + streetLine + cityLine + emailLine + phoneLine
      })
  }

  searchHall(e) {
    const hallId = e.target.value
    if (hallId == "") return

    fetch(`/accountant/search_hall`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify({ "hall_id": hallId })
    })
      .then(response => response.json())
      .then((data) => {
        const nameLine = data.hall_name ? `<p class="m-0">${data.hall_name.toUpperCase()}</p>` : ""
        const streetLine = data.street ? `<p class="m-0">${data.street}</p>` : ""
        const cityLine = data.city ? `<p class="m-0">${data.zipcode + " "}${data.city.toUpperCase()}</p>` : ""
        this.hallLogoTarget.src = data.photo_url
        this.billerNameTarget.textContent = `${data.legal_form.toUpperCase()} ${data.name.toUpperCase()}`
        this.hallDisplayTarget.innerHTML = nameLine + streetLine + cityLine
      })
  }

  #updateInvoiceDisplayingValue(tableRow) {
    const invoiceTotalInclVatInput = tableRow.querySelector(".invoiceTotalInclVatInput")
    const invoiceTotalExclVatInput = tableRow.querySelector(".invoiceTotalExclVatInput")
    const invoiceAmountPriceInput = tableRow.querySelector(".invoiceAmountInput")
    const invoiceAmountPrice = invoiceAmountPriceInput?.value || parseInt(invoiceAmountPriceInput?.innerText, 10) || 0
    const invoiceVatRate = tableRow.querySelector(".invoiceVatRate")?.value || parseInt(tableRow.querySelector(".serviceVatRateInput > .serviceVatRate")?.innerText, 10) || parseInt(tableRow.querySelector(".serviceVatRateSm")?.innerText, 10) || 0
    if (invoiceAmountPrice) {
      let quantity = tableRow.querySelector(".serviceQuantityInput")?.value || 1
      const totalExclVat = parseFloat(invoiceAmountPrice) * parseInt(quantity)
      const vatAmount = totalExclVat * parseFloat(invoiceVatRate) / 100
      const totalInclVat = (totalExclVat + vatAmount).toFixed(2)
      const invoiceTotalInclVatDisplaySpans = tableRow.querySelectorAll(".invoiceTotalInclVatDisplaySpan")
      invoiceTotalInclVatDisplaySpans.forEach((invoiceTotalInclVatDisplaySpan) => {
        invoiceTotalInclVatDisplaySpan.innerText = totalInclVat
      })
      if (quantity) {
        const serviceTotalExclVatDisplaySpans = tableRow.querySelectorAll(".serviceTotalExclVatDisplaySpan")
        serviceTotalExclVatDisplaySpans.forEach((serviceTotalExclVatDisplaySpan) => {
          serviceTotalExclVatDisplaySpan.innerText = totalExclVat
        })
      }
      invoiceTotalInclVatInput.value = totalInclVat * 100
      if (invoiceTotalExclVatInput) {
        invoiceTotalExclVatInput.value = totalExclVat * 100
      }
    }
  }

  #countInvoiceTotalVats() {
    const invoiceTotalWithVatSpan = document.getElementById("invoiceTotalWithVat")
    const invoiceTotalWithoutVatSpan = document.getElementById("invoiceTotalWithoutVat")
    const serviceInputs = document.querySelectorAll(".serviceTotalExclVatDisplayCell > .serviceTotalExclVatDisplaySpan")
    const invoiceInputs = serviceInputs.length === 0 ? document.querySelectorAll(".invoiceAmountInput") : serviceInputs
    let invoiceTotalExclVat = 0
    invoiceInputs.forEach((input) => {
      const value = parseFloat(input.value) || parseFloat(input.innerText) || ""
      if (value !== "") {
        invoiceTotalExclVat += parseFloat(value)
      }
    })
    invoiceTotalWithoutVatSpan.innerText = invoiceTotalExclVat.toFixed(2)

    let invoiceTotalInclVat = 0
    document.querySelectorAll(".invoiceTotalInclVatDisplayCell > .position-relative > .invoiceTotalInclVatDisplaySpan").forEach((span) => {
      if (parseFloat(span.textContent)) {
        invoiceTotalInclVat += parseFloat(span.textContent)
      }
    })

    invoiceTotalWithVatSpan.innerText = invoiceTotalInclVat.toFixed(2)
  }

  updateInvoicesValues(e) {
    const tableRow = e.target.closest("[data-invoice-target='tableRow']")
    if (e.currentTarget.classList.contains("invoiceVatRate")) {
      tableRow.querySelectorAll(".invoiceVatRate").forEach((vatRateInput) => {
        vatRateInput.value = e.currentTarget.value;
      })
    }
    this.#updateInvoiceDisplayingValue(tableRow);
    this.#countInvoiceTotalVats();
  }

  addInvoiceLine() {
    const lastId = this.tableRowTargets.pop().id
    const newId = parseInt(lastId, 10) + 1;
    let newInvoiceLine = document.querySelector('[id="0"]').outerHTML.replace(/\_0_/g, `_${newId}_`).replace(/\[0]/g, `[${newId}]`).replace(/id="0"/g, `id="${newId}"`);
    this.tableBodyTarget.insertAdjacentHTML(
      "beforeend", newInvoiceLine
    );
    newInvoiceLine = document.querySelector(`[id='${newId}']`)
    const removeLines = newInvoiceLine.querySelectorAll(".removeLine")
    removeLines.forEach((removeLine) => {
      removeLine.classList.remove("d-none")
    })
    newInvoiceLine.querySelectorAll(".invoiceTotalInclVatDisplaySmSpan, .invoiceAmount, .invoiceTotalInclVatDisplaySpan, .serviceTotalInclVatDisplaySmSpan, .serviceUnitPrice, .serviceTotalExclVatDisplaySpan, .serviceVatRate, .serviceVatRateSm, .serviceTotalInclVatDisplaySpan").forEach(el => {
      el.innerText = "-"
    })
  }

  removeInvoiceLine(e) {
    const quotationLine = e.target.closest("[data-invoice-target='tableRow']")
    quotationLine.remove();
    this.#countInvoiceTotalVats();
  }

  updateServiceRate(e) {
    const element = e.target
    const serviceCategoryId = e.target.value
    const hallId = e.target.dataset.hallid
    if (serviceCategoryId == "") return

    fetch(`/hall/halls/${hallId}/search_service_category`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify({ "service_category_id": serviceCategoryId })
    })
      .then(response => response.json())
      .then((data) => {
        const tableRow = element.parentElement.parentElement.parentElement
        const serviceVatRate = tableRow.querySelector(".serviceVatRateInput > .serviceVatRate")
        const serviceVatRateSm = tableRow.querySelector(".serviceVatRateSm")
        serviceVatRate.innerText = data.vat_rate
        serviceVatRateSm.innerText = data.vat_rate
        let quantity = tableRow.querySelector(".serviceQuantityInput").value
        if (quantity != "") {
          this.#updateInvoiceDisplayingValue(tableRow);
          this.#countInvoiceTotalVats();
        }
      })
  }

  updateServiceValues(e) {
    const tableRow = e.target.parentElement.parentElement.parentElement
    const quantity = e.target.value
    if (quantity != "") {
      this.#updateInvoiceDisplayingValue(tableRow);
      this.#countInvoiceTotalVats();
    }
  }


}
